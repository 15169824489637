
import * as customProviderRuntime$XgPdvHRg45 from '/home/ecomadm/app/releases/37/modules/rawlplug-base/utils/imgproxyProvider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "imgproxy",
  "domains": [
    "prod-ecom-cms.rawlplug.com",
    "prod-ecom-shop.rawlplug.com"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['imgproxy']: { provider: customProviderRuntime$XgPdvHRg45, defaults: {"baseURL":"https://imgproxy.rawlplug.com/"} }
}
        